'use client';

import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiMenu, BiX } from 'react-icons/bi';
import Link from 'next/link';
import { LogoIcon } from '../common/icons/logo.icon';
import { useTranslation } from '@/i18n/client';
import { useParams, usePathname } from 'next/navigation';
import { Locale } from '../../../i18n-config';
import LanguageChangeDropdown from '../common/LanguageChangeDropdown';
import LinkTo from '../common/LinkTo';

type Props = {
  theme?: string;
};

export default function SiteNavbarComponent({ theme }: Props) {
  const params = useParams();
  const pathname = usePathname();
  const { t } = useTranslation(params.lang as Locale);

  const navbarRef = useRef<any | null>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [drMenu, setDrMenu] = useState(false);
  const [show, setShow] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target) && menuOpen) {
        handleClose();
      }
    },
    [menuOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  if (pathname.includes('chat/')) return null;

  return (
    <React.Fragment>
      {show && (
        <div className="offer-alert tw-relative tw-min-h-[52px] tw-flex tw-items-center tw-w-full tw-justify-start  md:tw-justify-center tw-bg-[#19223F]  ">
          <Link
            href={`/${params.lang}/plans`}
            className="offer-alert tw-py-4 tw-text-center  tw-gap-2 tw-flex  tw-items-center tw-justify-content-center tw-text-[#EEF1FD] font-sofiaSans tw-text-sm hover:tw-text-white"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.26044 23.0854L21.6397 2.39572L19.7387 0.798828L17.0989 3.94141H4C2.897 3.94141 2 4.83841 2 5.94141V17.9414C2 18.9356 2.7288 19.7625 3.68035 19.9159L2.35938 21.4885L4.26044 23.0854ZM5.33892 17.9414H4V11.9414H10.3789L5.33892 17.9414ZM13.7389 7.94141L15.4189 5.94141H4V7.94141H13.7389ZM20 7.94141H18.569L15.2646 11.9414H20.001L20.002 17.9414H10.3081L8.65592 19.9414H20C21.103 19.9414 22 19.0444 22 17.9414V5.94141C22 5.38304 21.7701 4.87746 21.4 4.51432L20 6.2091V7.94141Z"
                fill="#889096"
              />
            </svg>

            {t('Layout/Banner/key1')}
          </Link>
          <BiX
            size={20}
            onClick={() => setShow(false)}
            className="tw-absolute tw-right-5 tw-top-4 tw-cursor-pointer"
            color="#697177"
          />
        </div>
      )}

      <Navbar
        expand="lg"
        sticky="top"
        className={`site-nav ${theme == 'light' ? 'bg-eightColor' : 'bg-firstColor'}`}
        onToggle={toggleMenu}
        expanded={menuOpen}
        ref={navbarRef}
        collapseOnSelect={true}
      >
        <Container fluid className="tw-justify-content-between align-items-center">
          <div className="tw-flex tw-items-center">
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 p-0 text-white me-3">
              <BiMenu className="menu-icon color-twentyOneColor" />
            </Navbar.Toggle>
            <Navbar.Brand as={'div'} className="me-3 pb-0">
              <Link
                href={`/${params.lang}`}
                onClick={() => {
                  handleClose();
                }}
                className="tw-flex tw-items-center tw-cursor-pointer"
              >
                <LogoIcon theme={'blue'} />
                <span className="font-sofiaSans fw-bold fs-4 tw-ml-2 tw-text-white">LouiseBot</span>
              </Link>
            </Navbar.Brand>
          </div>

          <Link
            href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${params.lang}/register`}
            onClick={() => {
              handleClose();
            }}
            className={`md:tw-hidden tw-w-[150px] tw-h-10 tw-px-5 tw-py-2.5 tw-bg-blue-600 tw-rounded-[10px] tw-justify-center tw-items-center tw-inline-flex tw-text-white`}
          >
            {t('SiteNavbar/l-04')}
          </Link>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkTo
                href={`/${params.lang}/`}
                className={`nav-link tw-cursor-pointer ${pathname === `/${params.lang}` ? 'active' : ''}`}
                onClick={() => {
                  handleClose();
                }}
              >
                {t('SiteNavbar/l-05')}
              </LinkTo>
              {/* <NavDropdown
                title={t('SiteNavbar/l-10')}
                id="features-dropdown"
                autoClose={'outside'}
                show={drMenu}
                rootCloseEvent={'click'}
                onToggle={() => setDrMenu(!drMenu)}
              >
                <NavDropdown.Item as={'div'}> */}
              <LinkTo
                href={`/${params.lang}/human-clone-page`}
                className={`nav-link tw-cursor-pointer ${
                  pathname === `/${params.lang}/human-clone-page` ? 'active' : ''
                }`}
                onClick={() => {
                  handleClose();
                  setDrMenu(false);
                }}
              >
                {t('SiteNavbar/l-06')}
              </LinkTo>
              {/* </NavDropdown.Item>
                <NavDropdown.Item as={'div'}> */}
              <LinkTo
                href={`/${params.lang}/video-chatbot`}
                className={`nav-link tw-cursor-pointer ${pathname === `/${params.lang}/video-chatbot` ? 'active' : ''}`}
                onClick={() => {
                  handleClose();
                  setDrMenu(false);
                }}
              >
                {t('SiteNavbar/l-07')}
              </LinkTo>
              {/* </NavDropdown.Item>
                <NavDropdown.Item as={'div'}> */}
              <LinkTo
                href={`/${params.lang}/text-chatbot`}
                className={`nav-link tw-cursor-pointer ${pathname === `/${params.lang}/text-chatbot` ? 'active' : ''}`}
                onClick={() => {
                  handleClose();
                  setDrMenu(false);
                }}
              >
                {t('SiteNavbar/l-08')}
              </LinkTo>
              {/* </NavDropdown.Item>
                <NavDropdown.Item as={'div'}> */}
              <LinkTo
                href={`/${params.lang}/ai-chatbot`}
                className={`nav-link tw-cursor-pointer ${pathname === `/${params.lang}/ai-chatbot` ? 'active' : ''}`}
                onClick={() => {
                  handleClose();
                  setDrMenu(false);
                }}
              >
                {t('SiteNavbar/l-09')}
              </LinkTo>
              {/* </NavDropdown.Item>
              </NavDropdown> */}
              <LinkTo
                href={`/${params.lang}/plans`}
                className={`nav-link tw-cursor-pointer ${pathname === `/${params.lang}/plans` ? 'active' : ''}`}
                onClick={() => {
                  handleClose();
                }}
              >
                {t('SiteNavbar/l-03')}
              </LinkTo>
              {/* <LinkTo
                href={`/${params.lang}/human-clone-page`}
                className={`nav-link tw-cursor-pointer ${
                  pathname === `/${params.lang}/human-clone-page` ? 'active' : ''
                }`}
                onClick={() => {
                  handleClose();
                }}
              >
                {t('SiteNavbar/l-06')}
              </LinkTo>
              <LinkTo
                href={`/${params.lang}/video-chatbot`}
                className={`nav-link tw-cursor-pointer ${pathname === `/${params.lang}/video-chatbot` ? 'active' : ''}`}
                onClick={() => {
                  handleClose();
                }}
              >
                {t('SiteNavbar/l-07')}
              </LinkTo> */}
            </Nav>
            <Nav style={{ gap: 10 }} className="extra-links">
              <LanguageChangeDropdown id="language-dropdown-navbar" />
              <Link
                href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${params.lang}/login`}
                className={`tw-h-10 tw-px-5 tw-py-2.5 tw-bg-[#19223F] tw-rounded-[10px] tw-justify-center tw-items-center tw-inline-flex tw-text-indigo-400 tw-text-sm tw-font-medium tw-font-['Sofia Sans'] tw-leading-tight`}
              >
                {t('SiteNavbar/l-01')}
              </Link>
              <Link
                href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${params.lang}/register`}
                className="tw-hidden  tw-h-10 tw-px-5 tw-py-2.5 tw-bg-blue-600 tw-rounded-[10px] tw-justify-center tw-items-center md:tw-inline-flex tw-text-white"
                onClick={() => {
                  handleClose();
                }}
              >
                {t('SiteNavbar/l-04')}
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}
